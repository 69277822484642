import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const CareerInConstruction: React.FC = () => {
  return (
    <>
      <SEO
        title="How to Build a Successful Career in Construction?"
        description="Career paths in construction are very varied. Some think through career development and training programs, while others simply promote themselves when a position opens up. In this article, we have compiled answers to some of the questions that will help with promotions in construction."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          {`Best Tips for Building a Successful\n Construction Career`}
        </Typography>
        <Typography variant="body1">
          Are you considering a career in construction? It is safe to say that
          it is one of the most lucrative career options available right now. So
          if you are thinking about it, it can be a smart move on your part.
        </Typography>
        <Typography variant="body1">
          Perhaps the most important thing to know about a{' '}
          <strong>career in construction</strong> is that a large factor for
          your advancement on the career path depends on who your employer is.
          While a few employers in this industry have a detailed career
          advancement path laid down for their employers, some will fill simply
          promote someone to fill up a vacant spot available.
        </Typography>
        <Typography variant="body1">
          A good degree depends on what your employer thinks about career
          advancement and how best you can manipulate it to advance your career
          growth. Here are some ways you can build a successful career in
          construction.
        </Typography>
        <Typography variant="h2" color="blue">
          Know Who You Are and the Career Path You Want
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/career-in-construction/image-1.png"
            alt="Know Who You Are and the Career Path You Want"
            title="Know Who You Are and the Career Path You Want"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Construction career paths are never the same for anyone. Knowing what
          it is that you want and what you are looking for is crucial. Are you
          looking to be a manager? Do you prefer an office job or a job in the
          field at a construction site? What kind of family life do you have?
          Are you looking for a career that allows you to have a lot of free and
          flexible time where you can pursue your family life and other
          interests? Are you looking for work in a specific geographical area?
        </Typography>
        <Typography variant="body1">
          All these have to be considered for determining a good construction
          career path that will help you reach your full potential.
        </Typography>
        <Typography variant="body1">
          Knowing yourself is a lot about understanding what is it you are
          after. Whether you are introverted or extroverted is a matter of
          thought. This is because there are lots of construction jobs that
          require brute strength while many focus on thoughtful analysis.
        </Typography>
        <Typography variant="body1">
          When you know what you want, you can decide on a construction career
          that plays to your style and strength.
        </Typography>
        <Typography variant="body1">
          This means far less stress in your professional life and a chance to
          enjoy the work you do.
        </Typography>
        <Typography variant="h2" color="blue">
          Never Stop Learning
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/career-in-construction/image-2.jpeg"
            alt="Never Stop Learning"
            title="Never Stop Learning"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          The construction industry is huge and if you are wondering how long it
          takes to become a construction worker, the answer is, not much. You
          can begin as a newbie workhorse almost immediately by training on the
          job.
        </Typography>
        <Typography variant="body1">
          The trick is to continue the learning process. The thing is, a lot of
          the parts of construction are changing dynamically. Technology is
          being introduced in many aspects and it is necessary for professionals
          in the field to constantly learn and update themselves with new
          knowledge.
        </Typography>
        <Typography variant="body1">
          There is a huge pressure to get construction projects finished on
          time. That is why reskilling professionals is necessary. Anyone who
          wants to make a career in this industry needs to constantly be on a
          learning spree. The people who do are most likely to succeed.
        </Typography>
        <Typography variant="h3">
          How to Become a Construction Worker with No Experience
        </Typography>
        <Typography variant="body1">
          If this is something you need to learn, the answer is in the below
          points:
        </Typography>
        <ul className={s.textLists}>
          <li>
            <Typography variant="body1">
              <strong>Strength-Based Resume</strong>: This is the first thing
              you need to do to get a job. Use the help of a professional
              construction worker to write a strength-based resume that focuses
              on your strengths and not your lack of experience. This will help
              recruiters see what your strengths are and why you are a good
              choice for the vacancy they have.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Manual Labour</strong>: If you want to find out{' '}
              <strong>how many years to become a construction worker</strong>,
              the answer can be zero if you are willing to take up manual labour
              jobs. Such jobs require little or no skill and most companies
              provide on-job training. It will help you get into the work field
              faster and with no experience at all. It will also help you gain
              experience.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Recommendations</strong>: If you have someone you know
              working in the construction field, having them recommend you for a
              job in their company is a great way to get in.
            </Typography>
          </li>
        </ul>
        <Typography variant="h2" color="blue">
          Train for the Next Position
        </Typography>
        <Typography variant="body1">
          As with any industry, a construction field is also a place where there
          are additional skills to be learned for every step ahead. Find out
          what the skills are that are needed for the next position and start
          training for them. Remember that the further up the ladder one goes,
          the more the need for soft skills will be. Don’t forget them when you
          train.
        </Typography>
        <Typography variant="body1">
          Whether it is leadership or communication skills, developing them is
          crucial and the better you are at these skills, the higher you will go
          in your career.
        </Typography>
        <Typography variant="h3">
          Do Communicate Your Career Position
        </Typography>
        <Typography variant="body1">
          It is critical to communicate that you are looking for a better career
          position. Usually, the boss is the person who has the keys. So letting
          your boss know that you wish to grow in your career is a very
          important thing to do.
        </Typography>
        <Typography variant="body1">
          When you regularly have chats about your career aspirations, your boss
          knows that you are looking for advancement. They are more likely to
          consider you for a higher position when a vacancy opens up.
        </Typography>
        <Typography variant="body1">
          If you are looking for someone to name{' '}
          <strong>3 career pathways for architecture and construction</strong>,
          the answer is:
        </Typography>
        <ol className={`${s.textLists} ${s.decimal}`}>
          <li>Construction</li>
          <li>Design or Pre-Construction</li>
          <li>Maintenance or Operations</li>
        </ol>
        <Typography variant="h3">Build Relationships</Typography>
        <Typography variant="body1">
          When you are looking for growth in your career, it is advisable to
          spend time and effort in building relationships. Whether it is
          friendship with co-workers or deeper bonds with people working in
          different companies in the construction field, relationships matter a
          lot.
        </Typography>
        <Typography variant="body1">
          Networking and knowing the right people will help you advance like
          nothing else will and they are critical aspects of career advancement.
          Connecting with others is a way of letting them know of your
          positives.
        </Typography>
        <Typography variant="body1">
          As you can well see, advancing in your career in construction doesn’t
          have to be too difficult. Being willing to learn on the job and newer
          skills as is about the industry at that point in time are crucial for
          advancement. If you are ready to do that, you will be good.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default CareerInConstruction
